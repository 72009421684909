<template>
  <div :style="this.CSS" :class="CSSClasses" v-show="!isHidden">
    <el-form-item
      ref="formItem"
      :prop="name"
      :rules="[
        { validator: this.requiredValidator, trigger: 'blur' },
      ]"
    >
      <el-checkbox-group
        @change="change"
        :disabled="_isReadonly"
        :name="name"
        :size="size"
        v-model="localModel"
      >
        <el-checkbox
          v-for="(item, index) in options"
          :key="index"
          :label="item.value"
          :is="type === 'basic' ? 'el-checkbox' : 'el-checkbox-button'"
        >
          <span :class="requiredStyle === 'asterisk' ? 'show_asterisk' : ''">{{ item.name }}</span>
        </el-checkbox>
      </el-checkbox-group>
    </el-form-item>

    <slot></slot>
  </div>
</template>

<script>
import mixin from '@/components/InterfaceEditor/components/mixins'
import RegistryMixin from '@/components/InterfaceEditor/components/registry/registry_mixins'
import { jsonParse } from '@/helpers'

export default {
  name: 'a-checkbox',

  mixins: [mixin, RegistryMixin],

  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {
      }
    }
  },

  props: {
    value: {
      frozen: true
    },

    model: {
      frozen: true
    },

    editorAlias: {
      type: String,
      description: 'alias'
    },

    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },

    width: {
      type: String,
      description: 'width'
    },

    type: {
      type: String,
      description: 'view',
      default: 'basic',
      editor: 'Select',
      options: {
        options: [
          { id: 'basic', name: 'Стандарт' },
          { id: 'button', name: 'Кнопки' }
        ]
      }
    },

    size: {
      type: String,
      description: 'size',
      editor: 'Size'
    },

    alwaysActive: {
      type: Boolean,
      description: 'always_active'
    },

    defaultCheck: {
      type: Boolean,
      description: 'default_check',
      frozen: true
    },

    options: {
      type: Array,
      default () {
        return [
          { name: 'Лэйбл', value: 'Значение' }
        ]
      },
      editor: 'Options'
    }
  },

  data () {
    return {
      localModel: this.$attrs.value || this.value || []
    }
  },

  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    },

    options: {
      handler: function (value) {
        if (!value[0].default) {
          this.$emit('change-property', { name: 'defaultCheck', value: false })
        }
      },
      deep: true
    },

    value: {
      handler: function (value) {
        if ((this.localModel || '').toString() !== (value || '').toString()) {
          this.localModel = (value || '').toString().split(',')
          this.parseValue()
        }
      }
    }
  },

  created () {
    // defaultCheck для сохранения старого функционала
    if (this.defaultCheck && this.options[0]) {
      this.localModel = [
        this.options[0].value
      ]
    }

    const defaultCheckbox = this.options
      .filter(item => item.default)
      .map(item => item.value)

    if (defaultCheckbox.length) {
      this.localModel = [...defaultCheckbox]
    }

    this.parseValue()
  },

  mounted () {
    if (this.localModel.length) {
      this.$emit('input', this.localModel.toString())
    }

    if (this.validationNeeded) {
      this.$refs.formItem.form.validateField(this.name)
    }
  },

  methods: {
    requiredValidator (rule, value, callback) {
      if (this._isRequired && (value === 'false' || !value || value === '')) {
        callback(this.$t('main.message.required_field'))
      } else {
        callback()
      }
    },

    parseValue () {
      if (typeof this.localModel === 'string') {
        const parsedValue = jsonParse(this.localModel)

        if (Array.isArray(parsedValue)) {
          this.localModel = parsedValue.map((item) => (item.id || item) + '')
        }
      }
    },

    change (event) {
      this.$emit('input', event.toString())

      if (this.name && this.$refs.formItem) {
        this.$refs.formItem.form.validateField(this.name)
      }
    }
  }
}
</script>
